import React from "react"
import Layout from "../components/Layout"

export default () => {
  return (
    <Layout>
      <main className="max-w-4xl flex-grow px-6 lg:px-0 md:mx-auto w-full flex flex-col">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8">
          <div>
            <h2 className="font-display font-bold text-xl mb-4">Luke Little Interview</h2>
            <div className="aspect-w-16 aspect-h-9">
              <iframe src="https://www.youtube.com/embed/oBTSHIC0344" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>

          <div>
            <h2 className="font-display font-bold text-xl mb-4">Player Interview - Drew</h2>
            <div className="aspect-w-16 aspect-h-9">
              <iframe src="https://www.youtube.com/embed/qmN3qdE3-GI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>

          <div>
            <h2 className="font-display font-bold text-xl mb-4">Player Interview - Nico</h2>
            <div className="aspect-w-16 aspect-h-9">
              <iframe src="https://www.youtube.com/embed/-v5B41p9AnQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>

          <div>
            <h2 className="font-display font-bold text-xl mb-4">Player Interview - Allen</h2>
            <div className="aspect-w-16 aspect-h-9">
              <iframe src="https://www.youtube.com/embed/PsGppnen3L0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
